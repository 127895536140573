/*******************
GLOBAL
*******************/

body {
	font-size: 18px;
}

.container {
    max-width: 1340px;
}

figcaption {
	font-size: 14px;
	max-width: 200px;

	span {
		font-size: 16px;
	}
}

.h1, h1 {

	.page-header & {
		font-size: 72px;
		margin-bottom: 20px;
	}

	.home & {
		font-size: 72px;
		span {
			font-size: 144px;
		}
	}

	.single-cpt_work_samples & {
		font-size: 78px;
		margin-bottom: 24px;

		&:before {
			top: -35px;
		}
	}

	&.black-lines {
		&:before {
			top: -25px;
			width: 250px;
		}
	}
}

h2, .h2 {

	#our-wares & {
		margin-bottom: 25px;
	}

	.work .work-container .portfolio-item & {
    	font-size: 28px;

    	&:before {
    		top: 0px;
    		@include opacity(0);
    	}
	}

	.single-cpt_work_samples .project & {
		font-size: 24px;

		.project-count {
			font-size: 24px;
		}

		.project-title {
			&:after {
				width: calc(100% + 44px);
				left: -44px;
			}
		}
	}
}

h3 {
	#peak-ethos & {
		font-size: 42px;
	}
}

.nav > li > a {
    padding: 10px 20px;
}

#page-head {
	min-height: 400px;
	padding-top: 35px;

	.subhead {
    	font-size: 32px;
	}
}

.home {
	#money-shot {
		padding-top: 60px;
		height: 700px;
	}
}

#home-blog {

	a:hover, a:focus {
		@include transition(background .25s ease);
		background: $eggshell-blue;
	}
}

.strong-man {
    right: -4%;
    bottom: -150px;
}

#our-wares {
	.btn {
		margin-top: 90px;
	}
}

#peak-ethos {

	.mission-dots {
		background-size: 50% auto;
	}

	div.slide {
		.container {
			max-width: 966.25px;
	    	padding-left: 0px;
	    	padding-right: 0px;
		}

		.ethos-content {
			padding-left: 0px;
	    	padding-right: 0px;
	    	font-size: 22px;
		}
	}

	.col-md-7 {
		width: 57.9999999%;
	}

	figure.owl-heart.visible, figure.dr-small-type.visible, figure.gemelos.visible {
		@include opacity(1);
	}

	figure.hang-glider {
		top: 50px;

		img {
			width: 350px;
		}
	}

	.mountains {
		width: 100%;
		bottom: -85px;
	}
}

@media screen and (min-width: 1300px) {
	.strong-man {
	    right: 0;
	}

	#our-wares {
		h2 {
	    	padding-right: 100px;
		}

		.col-md-4 {
    		text-align: center;
		}
	}
}

@media screen and (min-width: 1500px) {
	.strong-man {
	    right: -2%;
	}
}

#what-we-can-do {
	padding-bottom: 0px;
}

.work .work-container {
	.button-group {
	    max-width: 1340px;
	    left: 0;
	    right: 0;
	    padding-left: 25px;
	    top: -95px;
	}

	button {
		padding: 3px 14px;
	    font-size: 14px;
	    outline: none !important;
	    line-height: 34px;
	}

	.portfolio-item {
		.overlay {
	    	padding: 28px;

	    	.border span {
	    		position: relative;
	    		bottom: 0;
	    	}
		}

		h4 {
			font-size: 20px;
			margin-bottom: 15px;
		}
	}

	.overlay-content span.border {
		padding: 22px;
	}
}

.portfolio-item {
	.overlay {
		@include opacity(0);

		span {
			font-size: 13px;
		}
	}

	.work-container & {
		.overlay {
			@include opacity(0);
		}
	}

	.overlay-content {
		&:before {
	    	height: 0;
		}

		&:after {
			width: 0;
		}

		span.border {
			&:before {
				height: 0;
			}

			&:after {
				width: 0;
			}
		}
	}

	.overlay-color {
		@include opacity(0);
	}

	h2:before {
		opacity: 0;
	}

	&:hover {

		.overlay {
			@include opacity(1);
		}

		.overlay-color {
			@include opacity(.87);
		}

		&:before, &:after {
			@include opacity(1);
		}

		.overlay-content:before {
			height: calc( 100% + 4px );
			transition-delay: .3s;
		}

		.overlay-content:after {
			width: calc( 100% + 2px );
			transition-delay: .4s;
		}

		.overlay-content span.border:before {
			height: calc( 100% + 2px );
			transition-delay: .5s;
		}

		.overlay-content span.border:after {
			width: 100%;
			transition-delay: .6s;
		}

		.work .work-container & {
			h2:before {
				opacity: 1;
				top: 35px;
				transition-delay: .7s;
			}
		}
	}

	.home & {
		h3 {
			font-size: 28px;
			&:before {
				@include opacity(0);
				top: 0px;
			}
		}
	
		&:hover {

			h3:before {
				opacity: 1;
				top: 48px;
				transition-delay: .7s;
			}
		}
	}
}

/*******************
 TEAM
*******************/

.cd-panel__content ul {
	h4 {
		font-size: 20px;
	}

	span.employee-title {
      font-size: 18px;
    }

	p {
		font-size: 20px;
	}
}

.single-work-head {
	padding-bottom: 125px;
}

#page-head .cat-cycle {
    width: 320px;
    max-width: 320px;

    figcaption {
	    left: -30%;
	    top: 28%;
	}
}

/*******************
 WORK SINGLE
*******************/

.single-work-head {
	span {
		font-size: 18px;
	}
}

.project-intro {
	font-size: 22px;
}

.project {
	font-size: 18px;
}


@media screen and (min-width: 1600px) {

	.h1, h1 {
		.page-header & {
			font-size: 78px;
			margin-bottom: 25px;
		}
	}

	.work {
		.work-container {
			.grid-sizer,
			.portfolio-item {
				width: calc(33.3333333333% - 30px);
				margin-bottom: 30px;
			}
			.gutter-sizer { width: 45px; }

			.portfolio-item {
				padding-bottom: 20.5%;
				min-height: 224px;
			}
		}
	}

	#page-head {
		min-height: 440px;
	}
}
