
@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?73172865');
  src: url('../fonts/fontello.eot?73172865#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff2?73172865') format('woff2'),
       url('../fonts/fontello.woff?73172865') format('woff'),
       url('../fonts/fontello.ttf?73172865') format('truetype'),
       url('../fonts/fontello.svg?73172865#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  
}

[class^="icon-"]:before, [class*=" icon-"]:before {
	font-family: "fontello";
	font-style: normal;
	font-weight: normal;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	margin-left: .2em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-instagram:before { content: '\26'; } /* '&amp;' */
.icon-mail:before { content: '\e800'; } /* '' */
.icon-twitter:before { content: '\f309'; } /* '' */
.icon-facebook:before { content: '\f30c'; } /* '' */