
/*******************
GLOBAL
*******************/

figcaption {
	font-size: 12px;
	max-width: 160px;

	span {
		font-size: 14px;
	}
}

.spacer-150 {
	display: block;
}

.container {
	padding-left: 25px;
	padding-right: 25px;
}

/*******************
 HEADER
*******************/

.h1, h1 {
	font-size: 40px;

	.home & {
		font-size: 32px;

		span {
			font-size: 72px;
		}
	}
}

.h2, h2 {
	.work .work-container .portfolio-item & {
		font-size: 13px;
	}

	#money-shot & {
		width: 90%;
		line-height: 115%;
	}

	.home .portfolio-item & {
		font-size: 24px;
	}

	footer & {
		font-size: 32px;
	}
}

.h3, h3 {
	#what-we-do-best #btn-our-team & {
		font-size: 16px;

        &:after {
        	height: 44px;
        	width: 40px;
        	left: -15px;
        	top: 47px;
        }
	}

	#peak-ethos & {
		font-size: 24px;
	}

	.portfolio-item & {
		line-height: 110%;
	}

	footer & {
		font-size: 18px;
	}
}

.h4, h4 {
	.work .work-container .portfolio-item & {
		font-size: 13px;
		margin-top: 5px;
	}

	#what-we-do-best & {
		background: rgba(223,239,240,.9);
	}
}

a.brand {
	display: inline-block;
	width: 225px;
	height: 40px;

	.white-logo {
		max-height: 40px;
	}
}

#page-head {
	height: 56vh;

	.subhead {
		font-size: 24px;
	}

	.cat-cycle {
	    max-width: 250px;
	}
}

#money-shot {
	padding-top: 160px;
	height: 100vh;
	padding-left: 25px;

	h1 {
		position: relative;
		z-index: 10;
		padding-top: 20px;

		&:before {
			display: block;
			width: 196px;
			height: 11px;
			content: '';
			position: absolute;
			top: -15px;
			left: 0;
			background: url('../images/img-zig-zag-black@2x.png') repeat;
			z-index: 9998;
			background-size: 196px 11px;
		}
	}

	h2 {
		font-family: $taviraj;
		position: relative;
		z-index: 10;
		font-size: 24px;
		font-weight: 200;
		font-style: italic;
		margin-right: 0;
	}
}

.strong-man {
	bottom: -7%;
}

#our-wares {
	padding-top: 150px;

	.btn {
		margin-top: 50px;
	}
}

.portfolio-item {
	.home & {
		h3 {
			font-size: 26px;
			margin-bottom: 7px;

			&:before {
				top: 46px;
			}
		}

		h4 {
			font-size: 17px;
			margin-bottom: 15px;
		}
	}

	.overlay {
		padding: 26px;
	}
}

.fp-slide, .fp-slidesContainer {
    height: 100%;
}

/* home - what we do best */

#what-we-do-best {

	ul {
		background: rgba(223,239,240,.9);
	}

	h2 {
		font-size: 56px;
		text-align: center;
	}

	h3 {
		font-size: 32px;
		text-align: center;
	}

	#btn-our-team {
		padding: 12px;
		width: 103px;
		height: 140px;
		color: $black;
		font-size: 14px;
		background-size: 77px auto;
		background-position: 170% center;

		&.visible {
			right: 0px;
		}

		&:before {
			top: 50%;
			margin-top: -16px;
			width: 0;
			height: 0;
			border-width: 16px 15px 16px 0;
			border-color: transparent $white transparent transparent;
			left: -15px;
		}

		&:after {
		    width: 25px;
		    height: 25px;
		    left: -10.25px;
		}

		&:hover {
			text-decoration: none;
			background-color: $gray-blue;
			color: $white;
			background-position: 150% center;
			@include transition(background-position .35s ease-in-out);

			&:before {
				border-color: transparent $gray-blue transparent transparent;
			}

			h3:after {
	        	background: $gray-blue;
	        }
		}
	}

	.wwdb-row {
		padding-top: 25px;
	}

	.wwdb-daft-biker {
		margin-bottom: 0;
		padding-top: 20px;

		img {
			position: absolute;
		}
	}

	figcaption {
		display: none;
	}
}

.btn-wrap.our-team {

	&.visible {
		right: 0px;
	}

	&.visible-scd {

		&.visible-again {
			right: 0px;
		}
	}
}

/* home - ethos */

#peak-ethos {

}

#home-blog {
	margin: 50px 0;
}

.overlay-content span.border {
	padding: 36px 24px;

	.height-281 & {
		padding: 36px 18px;
	}
}

.work-container {

	.grid-sizer,
	.portfolio-item { width: calc(44% - 30px); }

	.gutter-sizer { width: 2%; }

	.portfolio-item {
		margin-bottom: 2%;
		padding-bottom: 69%;
		margin-left: 15px;
		margin-right: 15px;
		min-height: 175px;
	}

	.button-group {
	    padding: 18px 0 14px;
	}

	button {
		font-size: 12px;
		margin-right: 12px;
		letter-spacing: 2px;
	}

	.image-grid {
		padding: 10px 25px 25px;
		min-height: 230px;
	}

	.portfolio-item {
	    padding-bottom: 30%;
	}
}

#footer {
	.bg-white {
		padding: 80px 0 35px;
	}

	.address {

		a {
			font-size: 11px;
			span {

				&.city-state {

					span:after {
						content:'';
						clear: none;
						display: none;
					}
				}
			}
		}
	}
}