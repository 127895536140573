/*******************
GLOBAL
*******************/

body {
	font-size: 19px;
}

.h1, h1 {
	font-size: 56px;

	.single-cpt_work_samples & {
		font-size: 72px;
		margin-bottom: 18px;
	}
}

.h2, h2 {

	#money-shot & {
		padding-right: 30%;
	}

	.single-cpt_work_samples .project & {
		margin-bottom: 50px;
	}
}

.h3, h3 {
	#insta-peak & {
		margin-bottom: 35px;
	}
}

@media screen and (min-width: 1030px) {
	.h2, h2 {
		.work .work-container .portfolio-item & {
	    	font-size: 22px;

	    	&:before {
	    		top: 30px;
	    	}
		}
	}
}


#page-head {
	min-height: 240px;

	.subhead {
		width: 60%;
	}

	.cat-cycle {
		width: 50%;
		right: -40px;

		figcaption {
			left: -55%;
			top: 25%;
		}
	}
}


/*******************
HOME
*******************/


.strong-man {
    right: 0%;
    bottom: -11%;
    max-width: 40%;

    figcaption {
    	right: 75%;
    }
}

#what-we-do-best {
	padding-bottom: 220px;

	.wwdb-content {
		font-size: 18px;
	}

	.wwdb-daft-biker {
		bottom: -230px;

		img {
			bottom: -15%;
		}
	}

	figcaption {
		bottom: 0%;
		left: -20%;
	}
}

@media screen and (min-width: 992px) {
	#what-we-do-best {
		padding-bottom: 0px;

		figure {
			min-height: 400px;
		}

		.wwdb-daft-biker {
			position: relative;
			bottom: 0;
			left: 0;
		}

		figcaption {
			left: 0;
		}
	}
}

#peak-ethos {
	padding-left: 0px;
    padding-right: 0px;

    div.slide .container {
    	padding-left: 75px;
    	padding-right: 75px;
    }

	.ethos-nav {
		/* margin: 25px auto 0 -486px; */
		margin: 25px auto 0;
		top: 70px;

		li {
			a {
				padding: 0 30px;
			}
		}
	}

	figure.hang-glider {
		bottom: auto;
		top: 140px;

		figcaption {
			display: block;
    		margin: 50px 0 0 auto;
		}

		&.visible {
			figcaption {
				@include opacity(1);
			}
		}

		img {
			width: 250px;
		}
	}

	figure.gemelos {
		img {
	    	width: 317px;
		}

		&.visible {
			figcaption {
				@include opacity(1);
			}
		}
	}

	figure.dr-small-type {

		figcaption {
			display: block;
		}

		&.visible {
    		right: 40px;

    		figcaption {
				@include opacity(1);
    		}
		}

		img {
	    	width: 555px;
		}
	}

	figure.owl-heart {

		&.visible {
			figcaption {
				@include opacity(1);
			}
		}

		img.owl {
    		width: 316px;
		}
	}
}

@media screen and (min-width: 992px) {
	#peak-ethos {
		.col-md-7 {
    		width: 80%;
    	}

    	.ethos-nav li a {
		    font-size: 13px;
		}

		div.slide .container {
	    	padding-left: 0px;
	    	padding-right: 0px;
	    }
	}
}

@media screen and (min-width: 1100px) {
	#peak-ethos {
		.col-md-7 {
    		width: 65%;
    	}

    	.ethos-nav li a {
		    font-size: 16px;
		}
	}
}

#our-wares {
	.btn {
		margin-top: 50px;
	}
}


@media screen and (min-width: 992px) {
	.col-md-6.left-side {
		float: none;
		width: 100%;
	}

	.col-md-6.right-side {
		float: none;
		width: 100%;
	}
}

@media screen and (min-width: 1030px) {
	.cd-panel__content {
		ul {

		    li {

		    	img {
		    		@include opacity(1);
		    		@include transition(opacity .10s ease);
		    	}

				/* 
		    	img.front {
		    		@include opacity(1);
		    		@include transition(opacity .10s ease);
		    	}

				&:hover, &:active {
					img.front {
					  @include opacity(0);
					  @include transition(opacity .15s ease);

					  &.no-back {
					  	@include opacity(1);
					  }
					}
				} */
		    }
		}
	}
}

/*******************
 WORK GRID
*******************/

.work-container {

	.image-grid {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	button {
		font-size: 13px;
		padding-top: 0;
		padding-bottom: 0;
		height: 40px;
		line-height: 40px;

		&:before {
			content: '';
			position: absolute;
			top: calc(100% - 2px);
			display: block;
			width: 50px;
			max-width: 100%;
			left: 0;
			right: 0;
			margin: auto;
			border-bottom: 2px solid $white;
		}

		&:after {
		    border-top-color: $white;
		    top: calc(100% - 1px);
		}

		&.active {
		    background-color: $white;
		    color: $black;

		    &:after {
			    border-top-color: $white;
			}
		}
	}

	.button-group {
		position: absolute;
		top: -75px;
		background: none;
		padding-left: 50px;
	}
}

.portfolio-item {

	.home & {
		h3 {
			font-size: 26px;
		}

		h4 {
			font-size: 18px;
		}
	}
}

.work {
	.work-container {
		.grid-sizer,
		.portfolio-item {
			width: calc(50% - 20px);
			margin-bottom: 20px;
		}
		.gutter-sizer { width: 40px; }

		.portfolio-item {
			padding-bottom: 30%;
			min-height: 224px;
		}
	}
}

/*******************
 WORK SINGLE
*******************/

.single-work-head {
	padding-bottom: 75px;

	span {
		font-size: 18px;
	}

	.bg {
		display: block;
	}
}

.project-wrap {
    padding-top: 40px;
    &:last-of-type {
    	padding-bottom: 50px;
    }
}

.project-intro {
	max-width: 85%;
	font-size: 20px;
	margin-bottom: 0;
}

.project {
	font-size: 16px;

	p {
		line-height: 135%;
	}
}

.project-row {
	display: grid;
	grid-column-gap: 28px;
	padding: 30px 0;

	&.proj11 {
		grid-template-columns: 1fr 1fr;
	}

	&.proj333 {
		grid-template-columns: repeat(3, 1fr);
	}

	&.proj12 {
		grid-template-columns: 1fr 2fr;
	}

	&.proj21 {
		grid-template-columns: 2fr 1fr;

		&.reverse {
			direction: rtl;
		}
	}

	&:first-of-type {
		padding-top: 0;
	}

	&:last-of-type {
		padding-bottom: 50px;
	}

	img {
		width: 100%;
		max-width: 100%;
		vertical-align: top;
	}

	.img1-caption {
		display: block;
		margin-right: auto;
		margin-left: auto;
		display: block;
		max-width: 800px;
		clear: both;
	}
}

@media screen and (min-width: 1100px) {

	.portfolio-grid {
		margin-top: 50px;
	}

	.col-md-6.left-side {
		float: left;
		width: 50%;
	}

	.col-md-6.right-side {
		clear: none;
		padding-left: 8px;
		width: 50%;
		float: right;
	}
}

#footer {
	.address {
		width: 100%;
		margin-left: auto;
	}
}

.footer-contact {
	a {
		font-size: 18px;
	}
}