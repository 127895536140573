/*******************
GLOBAL
*******************/

body {
	font-size: 18px;
	line-height: 1.3;
}

/* SCROLL WRAP */

.scrollwrap {
	display: block;
	position: fixed;
	z-index: 9995;
	background-color: $black;
	width: 42px;
	height: 100%;
	top: 0;
	-webkit-transition: -webkit-transform 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;
	-moz-transition: -moz-transform 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;
	transition: transform 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;
	font-size: 10px;

	&.left {
		left: 0;
		-webkit-transform: translateX(-100%);
		-moz-transform: translateX(-100%);
		-ms-transform: translateX(-100%);
		-o-transform: translateX(-100%);
		transform: translateX(-100%);
	}

	&.right {
		right: 0;
		-webkit-transform: translateX(100%);
		-moz-transform: translateX(100%);
		-ms-transform: translateX(100%);
		-o-transform: translateX(100%);
		transform: translateX(100%);

		a {
			display: block;
			position: absolute;
			height: 30px;
			width: 14px;
			text-align: center;
			color: $light-teal;
			top: 55%;
			font-family: $taviraj;
			font-style: italic;
			font-size: 12px;
			background: url('../images/icon-arrow-up-white@2x.png') no-repeat center 6px;
			background-size: 10px auto;
			padding-top: 22px;
			left: 0;
			right: 0;
			margin: auto;
			border-top: 1px solid $light-teal;
			@include transition(all 0.3s linear);

			&:hover {
				background-position: center 4px;
				text-decoration: none !important;
				@include transition(all 0.1s linear);
			}
		}
	}

	body.scrollme & {
		&.left {
			-webkit-transform: translateX(0%);
			-moz-transform: translateX(0%);
			-ms-transform: translateX(0%);
			-o-transform: translateX(0%);
			transform: translateX(0%);
			-webkit-transition: -webkit-transform 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;
			-moz-transition: -moz-transform 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;
			transition: transform 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;
		}

		&.right {
			-webkit-transform: translateX(0%);
			-moz-transform: translateX(0%);
			-ms-transform: translateX(0%);
			-o-transform: translateX(0%);
			transform: translateX(0%);
			-webkit-transition: -webkit-transform 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;
			-moz-transition: -moz-transform 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;
			transition: transform 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;
		}
	}
}


.peak-row {
	padding-top: 150px;
	padding-bottom: 100px;
}

.h1, h1 {

	.home & {
		font-size: 58px;

		span {
			margin-top: 8px;
			letter-spacing: -1px;
			margin-left: -4px;
			line-height: 90%;
			font-size: 116px;
			margin-top: 12px;
			word-spacing: 9999px;
			letter-spacing: -7px;
			margin-left: -10px;
		}
	}

	&.black-lines {
		&:before {
			width: 160px;
		}
	}
}

.h2, h2 {
	font-size: 56px;

	.work .work-container .portfolio-item & {
		font-size: 18px;
	}

	.single-cpt_work_samples .project & {
		font-size: 18px;

		.project-count {
			font-size: 18px;
		}
	}

	#money-shot & {
		font-size: 28px;
		margin-top: 40px;
		line-height: 120%;
	}

	footer & {
		font-size: 40px;
		margin-bottom: 0;
	}
}

.h3, h3 {

	#peak-ethos & {
		font-size: 34px;
	}

	footer & {
		font-size: 26px;
		margin-top: 15px;
	}
}

.scroll-wrap {
	width: 100%;
}

.btn-wrap.our-team {

	&.visible {
		right: 40px;
	}

	&.visible-scd {

		&.visible-again {
			right: 40px;
		}
	}
}

.main {
	overflow: visible;
}

.container {
	width: calc(100% - 100px);
}

.banner {
	padding: 70px 0 100px;
	text-align: left;

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $black;
		z-index: 0;
		-webkit-transform: translateY(-100%);
		-moz-transform: translateY(-100%);
		-ms-transform: translateY(-100%);
		-o-transform: translateY(-100%);
		transform: translateY(-100%);
		-webkit-transition: -webkit-transform 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;
		-moz-transition: -moz-transform 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;
		transition: transform 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;

		body.scrollme & {
			-webkit-transform: translateY(0%);
			-moz-transform: translateY(0%);
			-ms-transform: translateY(0%);
			-o-transform: translateY(0%);
			transform: translateY(0%);
		}
	}

	&.autobots {
		padding: 24px 0 24px;
		transition: padding 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;

		.white-logo {
			right: auto;
		}

		.orange-logo {
			display: none;
		}
	}
}

.banner {
	padding: 50px 0;

	&.autobots {
		padding: 16px 0 10px;
		transition: padding 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;

		.white-logo {
			transition-delay: 1s;
			@include transition(right .2s ease-in);
		}
	}
}

/* home - hero text */

#money-shot {
	padding-left: 10px;
	padding-right: 10px;
	height: 95vh;
}

a.brand {
	width: 225px;
	height: 40px;

	.white-logo {
		fill: $white;
		max-height: 50px;
	    left: -4px;
	    top: -7px;
	    width: 60px;

	    &:hover, &:active {
	    	fill: $gray-blue;
	    }
	}
}

#menu-main-menu {
	float: right;
}

.cloud-01 {
	top: 0px;
	left: -5%;
}

.cloud-02 {
	top: 250px;
	left: 23%;
}

.cloud-03 {
	top: 0;
	left: 40%;
}

.cloud-04 {
	top: 150px;
	right: -5%;
}

.cloud-05 {
	top: 260px;
	right: 16%;
}

#home-blog {
	margin: 50px 0;

	li {
		display: inline;
		float: left;
		margin-right: 6px;
		width: calc(50% - 4.5px);
		margin-bottom: 0;

		&:nth-child(2n+2) {
			margin-right: 0;
		}
	}

	a {
		padding: 0 0 26px;
		font-size: 14px;
		height: 100%;

		> div {
			padding: 26px;
		}

		&:hover {

			.right-arrows {
				margin-left: 2px;
			}
		}

		.right-arrows {
			margin-left: 5px;
		}
	}

	time {
		font-size: 14px;
	}
}

/* strong man w/caption */

.strong-man {

	figcaption {
		right: 2%;
	}
}

@media screen and (max-height: 800px) and (max-width: 899px) {
	.strong-man {
		max-width: 45%;

		figcaption {
			right: 75%;
		}
	}
}

/* home - our work */

#our-wares {
	padding-top: 100px;
	padding-bottom: 60px;

	h2 {
		font-size: 32px;
		text-align: left;
		margin-top: 0;

		&:before {
			left: 15px;
			right: 0;
			margin-left: 0;
			margin-right: 0;
		}
	}

	.btn {
		margin-top: 80px;
	}

	.col-md-4 {
		
	}
}

.height-389 {
	height: 389px;
	padding-bottom: 0;
}

.height-281 {
	height: 281px;
	padding-bottom: 0;
}

.col-md-6.left-side {
	padding-right: 0;

	.height-389 {
		margin-bottom: 8px;
	}

	.height-281 {
		width: calc(50% - 4px);
		margin-bottom: 8px;

		&.col-md-6 {
			float: left;
		}

		&:last-child {
			margin-left: 8px;
		}
	}
}

.col-md-6.right-side {
	clear: both;
	padding-left: 0;

	.height-281 {
		width: calc(50% - 4px);
		margin-bottom: 8px;

		&.col-md-6 {
			float: left;
		}

		&:first-child {
			margin-right: 8px;
		}
	}

	.height-389 {
		clear: both;
	}
}

.portfolio-item {

	h2 {
		&:before {
			@include opacity(0);
			top: 0px;
		}
	}

	.home & {
		h3 {
			font-size: 22px;
			letter-spacing: -.005em;
		}

		h4 {
			font-size: 17px;
		}
	}

	h3 {
		font-size: 26px;

		&:before {
			@include opacity(0);
			top: 0px;
		}
	}

	h4 {
		font-size: 20px;
	}

	&:before, &:after {
		@include opacity(1);
	}

	.overlay {
		@include opacity(1);
	}

	.overlay-content:before {
		height: calc( 100% + 4px );
		transition-delay: .3s;
	}

	.overlay-content:after {
		width: calc( 100% + 2px );
		transition-delay: .4s;
	}

	.overlay-content span.border:before {
		height: calc( 100% + 2px );
		transition-delay: .5s;
	}

	.overlay-content span.border:after {
		width: 100%;
		transition-delay: .6s;
	}

	h2:before {
		opacity: 1;
		top: 48px;
		@include transition(all 0.3s linear);
		transition-delay: .7s;
	}
}

.overlay-content:before {
	height: 0;
}

.overlay-content:after {
	width: 0;
}

.overlay-content span.border:before {
	height: 0;
}

.overlay-content span.border:after {
	width: 0;
}

.portfolio-grid {
	display: block;
	margin-top: 80px;
}

#what-we-do-best {
	padding-top: 60px;
	padding-bottom: 190px;

	.wwdb-daft-biker {
		width: 310px;
	    position: absolute;
	    z-index: 100;
	    left: calc(50% - 165px);
	    display: block;
	    opacity: 1;
	    bottom: -200px;

		img {
			bottom: -15%;
		}
	}

	figcaption {
		display: block;
		bottom: 0%;
		left: -20%;
	}

	figure {
		min-height: 500px;
	}
}

#peak-ethos {
	left: 0;
	right: 0;
	padding-left: 42px;
    padding-right: 42px;

	div.slide {
		height: 95vh;
		padding-top: 300px;
	}

	.ethos-nav {
		margin: 25px auto 0 auto;
		left: 0;
		right: 0;
		width: calc(100% - 110px);

		ul {
			margin-left: auto;
			margin-right: auto;
		}

		li {
			width: auto;

			a {
				font-weight: 700;
				font-size: 10.5px;
				padding: 0 20px;
			}
		}
	}

	div.slide {

		.ethos-content {
	    	font-size: 18px;
		}

		.container {
			padding-left: 6px;
    		padding-right: 6px;
		}
	}

	.mountains {
	    bottom: -79px;
	    width: 200%;
	    background-size: 100% auto;
	    height: 213px;
	}

	figure.hang-glider {
		bottom: -150px;

		img {
	    	width: 200px;
		}

		figcaption {
			display: block;
	    	position: absolute;
	    	left: -20px;
	    	bottom: -125px;
		}
	}

	.mission-dots {
	    width: 200%;
	    background-size: 100% auto;
	}
}

#page-head {
	height: 30vh;
	border-bottom: 0;
	padding-top: 25px;

	.cat-cycle {
		right: 20%;
		max-width: 210px;

		figcaption {
			left: 110%;
			top: 42%;
			width: 140px;
		}
	}

	.subhead {
		width: 50%;
		font-size: 20px;
	}
}

.work-container {
	padding-left: 25px;
	padding-right: 25px;
	background: $white;
	border-top: 1px solid $gray;

	.grid-sizer,
	.portfolio-item { width: calc(48% - 2px); }

	.gutter-sizer { width: 5%; }

	.portfolio-item {
		margin-bottom: 2%;
		padding-bottom: 69%;
		margin-left: 15px;
		margin-right: 15px;
	}

	.button-group {
		text-align: left;
		width: calc(100% - 100px);
		margin-left: auto;
		margin-right: auto;
	}

	button {
		color: $black;
		margin-right: 20px;
	}

	.image-grid {
		width: calc(100% - 100px);
		margin-left: auto;
		margin-right: auto;
		padding-left: 0;
		padding-right: 0;
		min-height: 272px;
	}
}

.work {
	.work-container {
		.grid-sizer,
		.portfolio-item {
			width: calc(49% - 20px);
			margin-bottom: 20px;
			margin-left: 0;
			margin-right: 0;
		}
		.gutter-sizer { width: 40px; }

		.portfolio-item {
			padding-bottom: 30%;
			min-height: 224px;
		}
	}
}

#insta-peak {
	padding-top: 50px;
	padding-bottom: 50px;
}

#footer {
	.work & {
		padding-top: 100px;
	}

	.bg-white {
	    padding: 40px 0 35px;
	}

	.address {
		width: calc(100% + 20px);
		margin-left: -10px;
	}
}
