/* --------------------------------

Primary style

-------------------------------- */
*, *::after, *::before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/* --------------------------------

Slide In Panel - by CodyHouse.co

-------------------------------- */

.cd-btn {
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 5px rgba(0, 0, 0, 0.1);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 5px rgba(0, 0, 0, 0.1);
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
}

.cd-btn:hover {
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 20px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 20px rgba(0, 0, 0, 0.3);
}

.cd-panel {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  @include transition(visibility 1s ease-out);
}

.cd-panel::after {
  @include opacity(0);
  /* overlay layer */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../images/bg-peak-pattern-dark.png');
  background-size: 425px 325px;
  cursor: pointer;
  -webkit-transition: opacity .675s ease-out;
  -moz-transition: opacity .675s ease-out;
  -o-transition: opacity .675s ease-out;
  transition: opacity .675s ease-out;
  @include opacity(0);
  -webkit-animation: slide 30s linear infinite;
  -moz-animation-delay: slide 30s linear infinite;
  -ms-animation-delay: slide 30s linear infinite;
  -o-animation-delay: slide 30s linear infinite;
  animation-delay: slide 30s linear infinite;
  @-webkit-keyframes slide {
      from { background-position: 0 0; }
      to { background-position: 425px 0px; }
  }
}

.cd-panel.cd-panel--is-visible {
  visibility: visible;
  -webkit-transition: visibility 0s 0s;
  transition: visibility 0s 0s;
  z-index: 9999;
}

.cd-panel.cd-panel--is-visible::after {
  @include opacity(1);
  @include transition(opacity .25s ease-in-out);
}

.cd-panel__header {
  position: fixed;
  width: 90%;
  height: 50px;
  line-height: 50px;
  z-index: 9999;
  @include opacity(0);
  @include transition(all .15s linear);
}

.cd-panel--from-right .cd-panel__header {
  right: 0;
}

.cd-panel--from-left .cd-panel__header {
  left: 0;
}

.cd-panel--is-visible .cd-panel__header {
  @include opacity(1);
  @include transition(all .25s ease-in-out);
  background-color: rgba(134,171,173,.95);
  border-bottom: 2px solid $black;
}

@media only screen and (min-width: 768px) {
  .cd-panel__header {
    width: 50%;
    max-width: 480px;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-panel__header {
    width: 480px;
  }
}

.cd-panel__close {
  position: absolute;
  top: 3px;
  right: -3px;
  height: 46px;
  width: 60px;
  /* image replacement */
  display: inline-block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

.cd-panel__close::before, .cd-panel__close::after {
  /* close icon created in CSS */
  content: '';
  position: absolute;
  top: 20px;
  left: 0;
  height: 2px;
  width: 45px;
  background-color: $black;
  /* this fixes a bug where pseudo elements are slighty off position */
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.cd-panel__close::before {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.cd-panel__close::after {
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.cd-panel__close:hover {

}

.cd-panel__close:hover::before, .cd-panel__close:hover::after {
  background-color: $black;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.cd-panel__close:hover::before {
  -webkit-transform: rotate(225deg);
      -ms-transform: rotate(225deg);
          transform: rotate(225deg);
}

.cd-panel__close:hover::after {
  -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
          transform: rotate(135deg);
}

.cd-panel--is-visible .cd-panel__close::before {
  -webkit-animation: cd-close-1 0.6s 0.3s;
          animation: cd-close-1 0.6s 0.3s;
}

.cd-panel--is-visible .cd-panel__close::after {
  -webkit-animation: cd-close-2 0.6s 0.3s;
          animation: cd-close-2 0.6s 0.3s;
}

@-webkit-keyframes cd-close-1 {
  0%, 50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}

@keyframes cd-close-1 {
  0%, 50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}

@-webkit-keyframes cd-close-2 {
  0%, 50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
}

@keyframes cd-close-2 {
  0%, 50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
}

.cd-panel__container {
  @include  opacity(0);
  position: fixed;
  width: 90%;
  height: 100%;
  top: 0;
  background: $gray-blue;
  background-size: 200px auto;
  z-index: 9998;
  -webkit-transition: background-position 0.3s ease-out 1s, transform 0.3s 0.3s;
  -moz-transition: background-position 0.3s ease-out 1s, transform 0.3s 0.3s;
  -o-transition: background-position 0.3s ease-out 1s, transform 0.3s 0.3s;
  transition: background-position 0.3s ease-out 1s, transform 0.3s 0.3s;
  padding-bottom: 80px;

  &:after {
    display: block;
    z-index: -1;
    content: '';
    background: url('../images/bg-our-team-bear.png') no-repeat 200% 50px;
    background-size: 200px auto;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    @include transition(background-position .35s ease-in-out);
  }
  .cd-panel--is-visible & {
    @include  opacity(1);
    &:after {
      background-position: 100% 60px;
      @include transition(background-position .35s ease-in-out);
    }
    //
  }
}

.cd-panel--from-right .cd-panel__container {
  right: 0;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
}

.cd-panel--from-left .cd-panel__container {
  left: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
}

.cd-panel--is-visible .cd-panel__container {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

@media only screen and (min-width: 768px) {
  .cd-panel__container {
    width: 50%;
    max-width: 480px;
  }
}

@media only screen and (min-width: 1200px) {
  .cd-panel__container {
    width: 480px;
  }
}

.cd-panel__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 90px 5% 70px;
  overflow: auto;
  /* smooth scrolling on touch devices */
  -webkit-overflow-scrolling: touch;
  @include opacity(0);
  @include transition(opacity .25s linear);

  .cd-panel--is-visible & {
    @include opacity(1);
    @include transition(opacity .75s ease-in-out);
    -webkit-transition: opacity 0.75s ease-out .5s;
    -moz-transition: opacity 0.75s ease-out .5s;
    -o-transition: opacity 0.75s ease-out .5s;
    transition: opacity 0.75s ease-out .5s;
  }

  h2 {
    color: $white;
    padding-left: 5%;
    margin: 25px 0 0;
    position: relative;
    font-size: 56px;

    &:before {
      display: block;
      width: 114px;
      height: 11px;
      content: "";
      position: absolute;
      top: -28px;
      left: 0%;
      margin-left: 5%;
      background: url(../images/img-zig-zag-white.png) repeat-x;
      background-size: auto auto;
      background-size: 196px 11px;
    }
  }

  ul {
    list-style: none;
    margin: 25px 5px 115px 15px;
    padding-left: 0;

    h4 {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 14px;
      color: $white;
      border: 1px solid $white;
      font-weight: 700;
      padding: 8px 0 8px 12px;
      position:relative;
      margin-bottom: 6px;
    }

    li {
      color: $white;
      margin-bottom: 50px;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &:active {
        img.front {
          display: none;
          &.no-back {
            display: block;
          }
        }
      }

      .img-wrap {
        position: relative;
      }

      img {
        position: relative;
        z-index: 10;

        &.front {
          /* position: absolute; */
          z-index: 11;
        }

        &.no-back {
          position: relative;
        }
      }
    }

    span.employee-title {
      font-style: italic;
      font-size: 15px;
      font-weight: 700;
    }

    p, span.employee-title {
      margin-left: 12px;
      margin-right: 12px;
      color: $white;
    }

    p {
      font-size: 16px;
      margin-top: 15px;
      line-height: 135%;
      font-weight: 400;
    }

    a {
      position: absolute;
      right: 0;
      display: inline-block;
      border-left: 1px solid $white;
      padding: 0 10px 0 15px;
      color: $white;
      height: 100%;
      width: auto;
      top: 0;
      @include transition(background 0.1s linear);

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 6.25px;
        border-color: transparent transparent transparent $white;
        position: absolute;
        left: 0px;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      &:before {
        margin: 8px 0 0;
        font-size: 22px;
      }

      &:hover, &:active {
        background: $black;
        @include transition(background 0.25s ease-in-out);
        &:before {
          text-decoration: none;
        }
      }
    }
  }

  .btn-wrap {
    display: block;
    background-color: rgba(134,171,173,.95);
    height: 50px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    line-height: 50px;
    border-top: $black 2px solid;
    z-index: 12;
  }

  #team-to-top {
    display: block;
    font-family: $taviraj;
    font-style: italic;
    font-size: 14px;
    margin: 10px 0 0 20px;
    color: $white;
    line-height: 29px;
    text-decoration: none;

    &:before {
      content: '';
      display: inline-block;
      float: left;
      height: 30px;
      width: 13px;
      color: $white;
      background: url('../images/icon-arrow-up-white.png') no-repeat 0 6px;
      margin: 0 20px 0 0;
      border-top: 1px solid $light-teal;
      @include transition(all 0.3s linear);
    }
    &:hover {
      &:before {
        background-position: 0 4px;
        text-decoration: none !important;
        @include transition(all 0.1s linear);
      }
    }
  }
}

.cd-panel__content p {
  font-size: 1.4rem;
  color: #424f5c;
  line-height: 1.4;
  margin: 2em 0;
}


@media only screen and (min-width: 768px) {
  .cd-panel__content p {
    font-size: 1.6rem;
    line-height: 1.6;
  }
}