// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$black:					#4a4843;
$white:					#ffffff;
$light-teal:			#f0f7f7;
$gray:					#716f6a;
$orange:				#fc4c02;
$light-blue:			#dfeff0;
$eggshell-blue:			#ddeeef;
$gray-blue:				#86abad;
$maize:					#999b30;
$light-maize:			#d2c856;
$brand-primary:         $orange;

//Fonts

$taviraj:	'Taviraj', serif;
$montserrat:	'Montserrat', sans-serif;
