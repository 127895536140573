/*******************
GLOBAL
*******************/

html {
	scroll-behavior: smooth;
}

body {
	font-size: 100%;
	font-weight: 400;
	line-height: 1.35;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;

  	&.lock {
  		overflow: hidden !important;
  	}
}

p {
    margin: 0 0 16px;
}

img { // responsive images by default
	max-width: 100%;
	height: auto;
}

.scrollwrap {
	display: none;
}

.main {

	.home & {
		overflow: hidden;
	}
	padding-top: 120px;

	.single-cpt_work_samples & {
		padding-top: 0;
	}
}

.peak-row {
	padding-top: 50px;
	padding-bottom: 50px;
}

.spacer-150 {
	display: none;
}

figcaption {
	font-size: 10px;
	max-width: 140px;

	span {
		font-size: 12px;
	}
}

.iframe-wrap {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

/*******************
 HEADER
*******************/

a.brand {
	width: 160px;
	height: 38px;

	.white-logo {
		width: 60px;
		max-height: 40px;
		left: 0;
	}
}

.h1, h1 {
	font-family: $montserrat;
	font-weight: 600;
	position: relative;
	z-index: 10;
	font-size: 32px;

	.page-header & {
		font-weight: 700;
	}

	.home & {
		font-size: 20px;
	}

	span {
		display: block;
		font-weight: 900;
		line-height: 90%;
		text-transform: uppercase;
		font-size: 52px;
		margin-top: 8px;
		word-spacing: 9999px;
		letter-spacing: -1px;
		margin-left: -4px;
	}

	&.black-lines {
		&:before {
			display: block;
			width: 82px;
			height: 11px;
			content: '';
			position: absolute;
			top: -15px;
			left: 0;
			background: url('../images/img-zig-zag-black@2x.png') repeat-x;
			z-index: 9998;
			background-size: 196px 11px;
		}
	}

	.single-cpt_work_samples & {
		margin-top: 50px;

		&:before {
			top: -35px;
		}
	}
}

.h2, h2 {
	font-family: $montserrat;
	font-weight: 700;
	font-size: 30px;
	position: relative;
	z-index: 10;

	.single-cpt_work_samples .project & {
		text-transform: uppercase;
		padding-bottom: 15px;
		font-size: 14px;
		margin-bottom: 20px;
		letter-spacing: 2px;
		position: relative;

		.btm-line {
			position: absolute;
			display: block;
			border-bottom: 1px solid $black;
		}

		.project-count {
			font-style: italic;
			font-weight: 300;
			font-family: $taviraj;
			font-size: 16px;
			display: inline-block;
			margin-right: 10px;
			letter-spacing: 0;
		}

		.project-title {
			position: relative;

			&:after {
				content: "";
				position: absolute;
				display: inline-block;
				width: calc(100% + 36px);
				bottom: -14px;
				left: -36px;
				height: 2px;
				background: $black;
			}
		}

		.project-url-arrows {
			display: inline-block;
			color: $orange;
			margin: 0 8px;
			font-weight: 300;
			letter-spacing: 0;
		}

		a {
			text-transform: none;
			font-weight: 400;
			font-size: 14px;
			letter-spacing: 0;

			&:hover, &:active {
				text-decoration: none;
			}
		}
	}

	&.orange-lines {
		&:before {
			display: block;
			width: 196px;
			height: 11px;
			content: '';
			position: absolute;
			top: -40px;
			left: 15px;
			background: url('../images/img-zig-zag-orange@2x.png') repeat-x;
			background-size: 196px 11px;
		}
	}

	.work .work-container .portfolio-item & {
		font-size: 24px;

		&:before {
			top: 33px;
		}
	}

	footer & {
		font-size: 23px;
	}

	.project & {
		span.btm-border {
			display: block;
			width: 0;
			border-bottom: 1px solid $black;
			position: absolute;
		    bottom: 0;
		    left: 0;
		}
	}

	.project.is-visible & {
		span.btm-border {
			width: 100%;
			@include transition(width 1s ease-in-out);
		}
	}
}

.h3, h3 {
	letter-spacing: 1px;
	font-weight: 100;
	font-size: 22px;

	#home-blog & {
		font-weight: 700;
		font-family: $montserrat;
		font-size: 22px;
		margin: 12px 0;
	}

	#btn-our-team & {
		font-size: 6px;
		margin-top: 0;
		display: inline-block;
		writing-mode: tb-rl;
        transform: rotate(-180deg);
        position: absolute;
        left: 14%;
        top: 0;
        bottom: 0;
        margin: auto;
        letter-spacing: 2px;
        @include transition(left .35s ease-in-out);
        z-index: 11;

        &:after {
        	content: '';
        	display: block;
        	height: 24px;
        	width: 18px;
        	z-index: -1;
        	position: absolute;
        	background: $white;
        	left: -5px;
        	top: 23.5px;
        }
	}

	#peak-ethos & {
		font-size: 23px;
		line-height: 120%;
		margin: 0 0 20px;
		font-weight: 400;
	}

	footer & {
		font-size: 17px;
		font-weight: 300;
		letter-spacing: 0;
	}
}

.h4, h4 {
	font-size: 18px;
	font-family: $montserrat;
	letter-spacing: 1px;

	&.border {
		border-top: 2px solid $light-teal;
		border-right: 2px solid $light-teal;
		border-left: 2px solid $light-teal;
		padding: 12px;
		line-height: 18px;
		margin-top: 0;
	}

	.work .work-container .portfolio-item & {
		font-size: 10px;
		margin-top: 4px;
	}
}

.banner {
	padding: 15px 0;
	text-align: center;

	&.autobots {
		padding: 12px 0 5px;
		transition: padding 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;

		.white-logo {
			right: 0;
			transition-delay: 1s;
			@include transition(right .2s ease-in);
			fill: $white;
		}
	}
}

#menu-main-menu {
	display: inline;
	margin: 0;

	li {

		a {
			font-weight: 700;
			text-transform: uppercase;
			font-size: 14px;
			color: $black;
			@include transition(color 0.1s linear);

			body.scrollme & {
				color: $light-teal;
				@include transition(color 0.1s linear);

				&:hover, &:focus, &:active {
					color: $gray-blue;
				}
			}

			&:hover, &:focus, &:active {
				background: none;
				color: $gray-blue;
			}
		}
	}
}


/*******************
 HOME
*******************/

/* clouds */

.scroll-wrap {
	display: block;
	width: 100%;
	overflow: hidden;
	position: absolute;
	height: 100%;
	padding-left: 7px;
}

.tolstoy-tinkerbell {
	position: absolute;
	z-index: 1;
	top: 60px;
	left: 0;
	width: 100%;
	overflow: visible;

	.parallax {
		position: absolute;
	}
}

.cloud-01 {
	top: 0px;
	left: -5%;
}

.cloud-02 {
	top: 250px;
	left: 23%;
}

.cloud-03 {
	top: 0;
	left: 40%;
}

.cloud-04 {
	top: 150px;
	right: -5%;
}

.cloud-05 {
	top: 260px;
	right: 16%;
}


/* home - hero text */

#money-shot {
	display: block;
	position: relative;
	padding-top: 125px;
	height: 86vh;

	.home & {
		padding-top: 10px;
	}

	h1 {
		padding-top: 20px;
		max-width: calc(100% - 90px);
	}

	h2 {
		font-family: $taviraj;
		font-size: 16px;
		font-weight: 200;
		font-style: italic;
		margin-right: 10%;
		line-height: 135%;
	}
}


/* strong man w/caption */

.strong-man {
	position: absolute;
	z-index: 2;
	text-align: right;
	bottom: -7%;
	right: -7%;
	max-width: 60%;
	-webkit-animation-delay: 1s;
	-moz-animation-delay: 1s;
	-ms-animation-delay: 1s;
	-o-animation-delay: 1s;
	animation-delay: 1s;

	figcaption {
		position: absolute;
		bottom: 40%;
		right: 80%;
		width: 120%;
		-webkit-animation-delay: 1.5s;
		-moz-animation-delay: 1.5s;
		-ms-animation-delay: 1.5s;
		-o-animation-delay: 1.5s;
		animation-delay: 1.5s;
	}
}


/* home - our work */

#our-wares {
	padding-top: 85px;

	h2 {
		font-family: $taviraj;
		position: relative;
		font-size: 24px;
		font-weight: 200;
		line-height: 135%;
		text-align: center;

		&:before {
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.btn {
		margin-top: 25px;
	}

	.col-md-4 {
		text-align: center;
	}
}

.portfolio-grid {
	display: block;
	margin-top: 40px;
}

.col-md-6.left-side {
	padding-left: 0;
	padding-right: .25%;

	.height-389 {
		margin-bottom: 25px;
	}

	.height-281 {
		width: 100%;
		margin-bottom: 25px;

		&:first-child {
			margin-right: 1%;
		}

		&:last-child {
			margin-left: 1%;
		}
	}
}

.col-md-6.right-side {
	padding-right: 0;
	padding-left: .25%;

	.height-389 {
		margin-bottom: 25px;
	}

	.height-281 {
		width: 100%;
		margin-bottom: 25px;

		&:first-child {
			margin-right: 1%;
		}

		&:last-child {
			margin-left: 1%;
		}
	}
}

.portfolio-item {
	position: relative;
	display: block;
	background-size: cover;
	background-repeat: no-repeat;
	color: $light-teal;

	&:before, &:after {
		position: absolute;
		top: 25px;
		right: 28px;
		bottom: 25px;
		left: 28px;
		content: '';
		z-index: 11;
		@include transition(opacity .35s ease);
	}

	h2, h3 {
		font-size: 14px;
		margin-top: 0;
		line-height: 125%;
		margin-bottom: 4px;
		font-weight: 700;
		text-transform: none;
		overflow-wrap: break-word;

		&:before {
			@include opacity(1);
			position: absolute;
			content: '';
			left: 0px;
			top: 39px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5px 0 5px 7px;
			border-color: transparent transparent transparent $light-teal;
			@include transition(all .2s ease-in);
			transition-delay: 0s;
		}
	}

	h2 {
		position: static;
	}

	h4 {
		font-size: 12px;
		font-family: $taviraj;
		font-weight: 700;
		font-style: italic;
		text-transform: none;
		margin: 4px 0 12px;
	}

	.overlay {
		@include opacity(1);
		@include transition(opacity .15s ease);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-family: $montserrat;
		font-weight: 700;
		padding: 16px;

		span {
			font-size: 11px;
			text-transform: uppercase;
			font-weight: 700;
			letter-spacing: 1px;
		}
	}

	.overlay-color {
		position: absolute;
		z-index: 10;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		@include opacity(.75);
		color: $light-teal;
	}

	.overlay-content {
		z-index: 11;
		text-align: left;
	}

	&:hover {
		color: $light-teal;

		&:before, &:after {
			@include opacity(1);
		}

		.overlay {
			@include transition(opacity .35s ease);
			@include opacity(1);
		}

		.overlay-content span.border {
			color: #ffffff;
		}
	}
}

.overlay-content {
	cursor: pointer;
	box-sizing: border-box;
	text-transform: uppercase;
	position: relative;
	z-index: 20;
	width: 100%;
	height: 100%;
	border: 2px solid transparent;
}

.overlay-content span.border {
	color: #ffffff;
	padding: 32px 24px;
	box-sizing: border-box;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.height-281 & {
		padding: 32px 18px;
	}
}

.overlay-content:before,
.overlay-content:after,
.overlay-content span.border:before,
.overlay-content span.border:after {
	content: '';
	position: absolute;
	transition: width .1s, height .1s;
	background-color: $light-teal;
}

.overlay-content:before {
	width: 2px;
	top: -2px;
	right: -2px;
	transition-delay: 0s;
	height: calc( 100% + 4px );
}

.overlay-content:after {
	width: calc( 100% + 2px );
	height: 2px;
	bottom: -2px;
	right: 0;
	transition-delay: 0s;
}

.overlay-content span.border:before {
	width: 2px;
	height: calc( 100% + 2px );
	left: -2px;
	bottom: 0;
	transition-delay: 0s;
}

.overlay-content span.border:after {
	width: 100%;
	height: 2px;
	top: -2px;
	left: 0;
	transition-delay: 0;
}

.height-389, .height-281 {
	height: 0;
	padding-bottom: 69%;
}


.svg-wrapper {
	position: relative;
	z-index: 20;
	top: 50%;
	transform: translateY(-50%);
	margin: 0 auto;
	width: 100%;
}

.shape {
	stroke-dasharray: 140 540;
	stroke-dashoffset: -474;
	stroke-width: 2px;
	fill: transparent;
	stroke: $light-teal;
	transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
}

/* home - what we do best */

#what-we-do-best {
	position: relative;
	background: url('../images/bg-what-we-do-best.png') no-repeat;
	background-size: 100% auto;
	background-position: center 110px;
	background-attachment: fixed;
	overflow: visible;

	.wwdb-row {
		padding-top: 0px;
	}

	.wwdb-daft-biker {
		padding-top: 40px;
		position: relative;
		margin-bottom: 50px;

		img {
			position: relative;
		}
	}

	figure {
		position: relative;
		display: block;
		height: 100%;
	}

	figcaption {
		position: absolute;
		background: rgba(223,239,240,.92);
		bottom: 31%;
		left: 8%;
		max-width: 200px;

		span {
			background: $light-blue;
		}

		em {
			display: block;
			margin-top: 8px;
		}
	}

	.wwdb-content {
		font-weight: 400;
		position: relative;
		font-size: 16px;
		font-family: $taviraj;
	}

	ul {
		list-style: none;
		border-top: 2px solid $light-teal;
		margin: 0 0 25px 0;
		-webkit-column-count: 2;
		-moz-column-count: 2;
		column-count: 2;
		-moz-column-gap: 20px;
		-webkit-column-gap: 20px;
		column-gap: 20px;
		padding: 15px 0 15px 14px;
		height: auto;

		li {
			margin-bottom: 6px;
		}
	}

	h2 {
		font-size: 28px;
		font-family: $montserrat;
		font-weight: 700;
	}

	h3 {
		font-size: 22px;
		font-weight: 200;
	}

	h4 {
		display: inline-block;
		text-transform: uppercase;
		position: relative;
		margin-bottom: 0;
		font-weight: 700;
	}
}

.btn-wrap.back-to-work {
	margin: 50px 0;
}

.btn-wrap.our-team {
	position: fixed;
	z-index: 9990;
	right: -130px;
	top: 50%;
	margin-top: -70px;
	@include transition(right .45s ease-in-out);

	&.visible {
		right: 0px;
		@include transition(right 1s ease-in-out);
	}

	&.visible-scd {
		right: -130px;

		&.visible-again {
			right: 0px;
		}
	}
}

#btn-our-team {
	display: inline-block;
	overflow: uppercase;
	padding: 6px;
	width: 51.5px;
	height: 70px;
	color: $black;
	text-transform: uppercase;
	font-size: 8px;
	font-family: $montserrat;
	font-weight: 700;
	text-align: center;
	background: $white url('../images/img-bicycle-bear@2x.png') no-repeat;
	background-size: 38.5px auto;
	background-position: 110% center;
	text-decoration: none;
	box-shadow: 0 0 6.3px 0.7px rgba(74, 72, 67, 0.4);
	z-index: 100;
	@include opacity(1);
	@include transition(background-position .35s ease-in-out);

	#what-we-do-best & {
		h3 {
			font-size: 8px;
			left: 8%;
			top: -4px;
			letter-spacing: 1.5px;
		}
	}

	&.visible {
		right: 0px;
	}

	&:before {
		display: block;
		position: absolute;
		top: 50%;
		margin-top: -16px;
		content: "";
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 16px 15px 16px 0;
		border-color: transparent $white transparent transparent;
		left: -15px;
		z-index: 1;
	}

	&:after {
		display: block;
	    content: '';
	    position: absolute;
	    transform: rotate(45deg);
	    width: 25px;
	    height: 25px;
	    left: -10.25px;
	    box-shadow: 0 0 6.3px 0.7px rgba(74, 72, 67, 0.4);
	    top: 0;
	    bottom: 0;
	    margin: auto;
	    background: $gray-blue;
	    z-index: -1;
	}
}

/* home - ethos */

#peak-ethos {
	padding: 0;
	color: $light-teal;
	text-align: center;
	overflow: hidden;
	font-size: 16px;

	.section {
		height: auto;
	}

	.ethos-nav {
		display: block;
		width: 100%;
		position: absolute;
		top: 40px;
		left: 0;
		z-index: 100;
		height: 35px;
		font-family: $montserrat;
		font-weight: 400;
		padding: 0;
		margin: 0 0 25px;

		ul {
			display: inline-block;
			width: auto;
			padding: 0;
			margin: 35px auto;
			list-style: none;
			border-top: $light-teal 1px solid;
			border-bottom: $light-teal 1px solid;
		}

		li {
			display: inline-block;
			width: 100%;
			text-transform: uppercase;
			height: 35px;
			margin: 0;

			&:first-child a.active {
				color: $gray;
			}

			&:nth-child(2) a.active {
				color: $orange;
			}

			&:nth-child(3) a.active {
				color: $maize;
			}

			&:nth-child(4) a.active {
				color: $gray-blue;
			}
		}

		a {
			display: block;
			width: 100%;
			position: relative;
			margin: 0;
			height: 35px;
			line-height: 35px;
			padding: 0 30px;
			color: $light-teal;

			&:hover, &:active, &:focus {
				text-decoration: none !important;
			}

			&.active {
				background: $light-teal;
	  			text-decoration: none;

	  			&:after {
	  				display: block;
	  				content: '';
	  				position: absolute;
	  				right: -6px;
	  				top: 50%;
	  				margin-top: -6px;
	  				width: 0;
					height: 0;
					border-style: solid;
					border-width: 6px 0 6px 6px;
					border-color: transparent transparent transparent $light-teal;
	  			}
			}
		}
	}

	div.slide {
		padding-top: 330px;
		height: 100%;
		text-align: left;

		.ethos-content {
			font-size: 14px;
			font-weight: 400;
		}

		.container {
			width: 100%;
			max-width: 932px;
			padding-left: 0;
			padding-right: 0;
			margin-bottom: 50px;
		}

		&[data-anchor="huge-collaboration"] {
			background-color: $orange;
		}

		&[data-anchor="no-small-type"] {
			background-color: $maize;
		}

		&[data-anchor="our-mission"] {
			background: $gray-blue;
		}
	}

	h2:before {
		display: block;
		width: 196px;
		height: 11px;
		content: '';
		position: absolute;
		top: -25px;
		left: 50%;
		margin-left: -98px;
		background: url('../images/img-zig-zag-white.png') repeat-x;
		background-size: 196px 11px;
	}

	.mountains {
		display: block;
		content: '';
		position: absolute;
		bottom: -98px;
		width: 200%;
		background: url('../images/bg-mountains.png') repeat-x;
		background-size: 50% auto;
		height: 141px;
		left: 0;
	}

	.mission-dots {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: url(../images/bg-heart-pattern@2x.jpg) repeat;
		background-size: 1400px auto;
	}

	figure.gemelos {
		display: block;
		content: '';
		position: absolute;
		bottom: -500px;
		right: 20%;
		@include opacity(0);

		&.visible {
			@include opacity(.3);
			bottom: 0;
			@include transition(bottom .35s ease-in-out);

			figcaption {
				@include transition(all .35s .75s ease-in-out);
				@include opacity(0);
				bottom: 100px;
			}
		}

		img {
			width: 200px;
		}

		figcaption {
			@include opacity(0);
			@include transition(all .35s ease-in-out);
			position: absolute;
			right: -150px;
			bottom: 80px;
			max-width: 170px;
		}
	}

	figure.hang-glider {
		position: absolute;
		right: 140px;
		bottom: -120px;

		img {
			width: 125px;
			height: auto;
		}

		figcaption {
			display: none;
		}

		em {
			display: block;
			margin-top: 8px;
			a {
				color: $light-maize;
			}
		}
	}

	figure.dr-small-type {
		position: absolute;
		right: -550px;
		bottom: 0;

		img {
			width: 255px;
		}

		&.visible {
			@include opacity(.4);
			right: 0px;
			@include transition(right .35s .25s ease-in-out);

			figcaption {
				@include transition(all .35s .95s ease-in-out);
				@include opacity(0);
				bottom: 110px;
			}
		}

		figcaption {
			position: absolute;
			bottom: 90px;
			right: 130px;
			max-width: 170px;
			@include opacity(0);
			@include transition(all .35s ease-in-out);
		}

		em {
			display: block;
			margin-top: 8px;
		}
	}

	figure.owl-heart {
		position: absolute;
		right: 20%;
		bottom: -40px;
		@include opacity(0);

		img.owl {
			width: 196px;
			position: relative;
			z-index: 10;
		}

		img.heart {
			position: absolute;
			z-index: 11;
			width: 121px;
			left: 92px;
			top: 145px;
		}

		&.visible {
			@include opacity(.4);
			@include transition(opacity .35s .25s ease-in-out);

			figcaption {
				@include transition(all .35s .95s ease-in-out);
				@include opacity(0);
			}
		}

		figcaption {
			position: absolute;
			bottom: 125px;
			right: -160px;
			max-width: 170px;
			@include opacity(0);
		}

		em {
			display: block;
			margin-top: 8px;
		}
	}
}

/* 5s and less */
@media screen and (max-width: 374px) {
	#slides {
		min-height: 730px !important;
	}
}

/* 6s & 7 and less */
@media screen and (max-width: 413px) {

	.portfolio-item {

		h3 {
			font-size: 16px;
		}

		h4 {
			font-size: 14px;
		}
	}

	#slides {
		min-height: 680px !important;
	}
}

@media screen and (min-width: 413px) {
	h3 {
		.home .portfolio-item & {
			font-size: 21px;

			&:before {
				top: 41px;
			}
		}
	}
}

/* 6 & 7 plus and less */
@media screen and (max-width: 599px) {

	.portfolio-item {

		.work .work-container & {
			padding-bottom: 36%;
		}

		h2, h3 {
			font-size: 18px;
		}

		h4 {
			font-size: 15px;
		}
	}

	#slides {
		min-height: 650px !important;
	}
}

.fp-slide {
    height: auto;
}

.fp-slidesContainer {
    min-height: 100%;
    height: auto;
}

#insta-peak {
	text-align: center;

	h2:before {
		display: block;
	    width: 196px;
	    height: 11px;
	    content: "";
	    position: absolute;
	    top: -25px;
	    left: 50%;
	    margin-left: -98px;
	    background: url(../images/img-zig-zag-black.png) repeat-x;
	    background-size: 196px 11px;
	}
}

#home-blog {
	display: block;
	list-style: none;
	margin: 50px 0 25px;
	padding-left: 0;
	text-align: left;

	li {
		display: block;
		width: 100%;
		margin-bottom: 25px;

		&:last-child {
			margin-right: 0;
		}
	}

	img {
		width: 100%;
	}

	a {
		display: block;
		background: #f0f7f7;
		color: $black;
		font-family: $montserrat;
		padding: 0 0 26px;
		text-decoration: none;
		@include transition(background .1s ease-in);
		font-size: 14px;

		> div {
			padding: 26px;
		}

		&:hover {
			text-decoration: none;

			.right-arrows {
				margin-left: 2px;
				@include transition(margin-left .1s ease-in);
			}

			span {
				color: $orange;
				@include transition(color .1s ease-in);
			}
		}

		span {
			text-transform: uppercase;
			font-weight: 700;
			@include transition(color .25s ease-in);
		}

		p {
			font-family: $taviraj;
		}

		.right-arrows {
			margin-left: 5px;
			@include transition(margin-left .25s ease-in);
		}
	}

	time {
		font-weight: 700;
		font-size: 14px;
	}
}

.page-header {
	margin: 0;
	border: 0;
}

#page-head {
	height: 52vh;
	border-bottom: 1px solid $gray;

	.subhead {
		font-size: 20px;
		line-height: 125%;
	}

	.top-img {
		position: absolute;

		img {
			width: 100%;
			height: auto;
		}
	}

	.cat-cycle {
		bottom: 0;
		right: 0;
		width: 50%;
		max-width: 210px;

		figcaption {
			position: absolute;
			left: -55%;
			top: 25%;
		}
	}
}

.work-container {
	display: block;
	padding-left: 0;
	padding-right: 0;
	text-align: center;
	position: relative;

	.button-group {
		display: block;
		background: $white;
		padding: 8px 0 14px;
		font-size: 0;
	}

	button {
		position: relative;
		color: $black;
		border: 0;
		font-family: $montserrat;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 9px;
		border-bottom: 1px solid $eggshell-blue;
		background-color: transparent;
		padding: 3px 8px;
		margin-right: 3px;
		@include transition(background-color .15s ease-in-out);

		&:last-of-type {
			margin-right: 0;
		}

		&:active {
			outline: none;
		}

		&.active {
			background-color: $orange;
			color: $white;
			@include transition(background-color .35s ease-in-out);
			outline: none;

			&:after {
				border-top-color: $orange;
			}
		}

		&:after {
			top: 100%;
			left: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(255, 255, 255, 0);
			border-top-color: $eggshell-blue;
			border-width: 6px;
			margin-left: -6px;
		}
	}

	.grid-sizer,
	.portfolio-item { width: 90%; }

	.gutter-sizer { width: 4%; }

	.portfolio-item {
		margin-top: 15px;
		display: inline-block;
		position: relative;
		padding-bottom: 35px;
		min-height: 230px;

		.img-wrap {
			display: block;
			clear: both;
		}

		.overlay {
			display: block;
			position: absolute;
			z-index: 10;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			background-size: cover;
			@include transition(opacity 0.25s linear);
			@include opacity(1);
			padding: 8px;

			span {
				position: absolute;
				bottom: 12px;
			}
		}
	}

	.overlay-content {
		span.border {
			padding: 22px 16px;
		}
	}

	.image-grid {
		position: relative;
		display: block;
		padding: 10px 5% 20px;
		background: $white;
	}
}


@media screen and (min-width: 414px) {
	.work-container {
		button {
			font-size: 11px;
			margin-right: 4px;
		}
	}
}


/*******************
 WORK SINGLE
*******************/

.single-work-head {
	position: relative;
	padding-top: 150px;

	.bg {
		position: fixed;
		display: none;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-size: cover;
		background-position: center top;

		&:after {
			content: '';
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			position: absolute;
			@include opacity(.9);
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#dfeff0+46,d0e3e4+100 */
			background: #dfeff0; /* Old browsers */
			background: -moz-radial-gradient(center, ellipse cover, #dfeff0 46%, #d0e3e4 100%); /* FF3.6-15 */
			background: -webkit-radial-gradient(center, ellipse cover, #dfeff0 46%,#d0e3e4 100%); /* Chrome10-25,Safari5.1-6 */
			background: radial-gradient(ellipse at center, #dfeff0 46%,#d0e3e4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dfeff0', endColorstr='#d0e3e4',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
		}
	}
	span {
		display: block;
		margin-bottom: 40px;
		font-style: italic;
		font-family: $taviraj;
		font-weight: 300;
		font-size: 14px;
	}
}

.project-intro {
	font-size: 17px;
	line-height: 135%;
	margin-bottom: 25px;
}

.project-wrap {
	display: block;
	position: relative;
	padding-top: 25px;
	padding-bottom: 25px;
	background: $white;
}

.project {
	font-size: 14px;
	line-height: 135%;
	visibility: hidden;
	padding-top: 25px;
}

.project-row {
	visibility: hidden;

	.project-blk {
		margin-bottom: 15px;
	}

	.img1-caption {
		padding-top: 10px;

		p {
			margin-bottom: 0;
		}
	}
}

/*******************
 FOOTER
*******************/

#footer {
	background: url('../images/bg-peak-pattern@2x.jpg') repeat;
	background-attachment: fixed;
	padding: 30px 0 105px;

	.home &, .work & {
		padding: 30px 0 110px;
	}

	.bg-white {
		padding: 40px 0 35px;
	}

	.peak-mark {
		width: 64px;
		margin-bottom: 5px;
	}

	.address {
		display: block;
		text-transform: uppercase;
		font-family: $montserrat;

		a {
			font-size: 13px;
			letter-spacing: 2px;
			font-weight: 400;

			&:hover, &:focus {
				text-decoration: none;
				color: $orange;

				span.city-state {
					$color: $gray;
				}
			}

			span {
				margin: 0 8px;

				&.city-state {
					margin: 0;
					color: $gray;

					span:after {
						content:'';
						clear: both;
						display: block;
					}
				}
			}
		}
	}

	#social-nav {
		display: block;
		text-align: center;
		position: absolute;
		list-style: none;
		bottom: -110px;
		left: 0;
		right: 0;
		margin: 0;
		padding: 0;

		li {
			display: inline;
			font-size: 30px;

			a {
				color: $black;

				&:hover {
					color: $orange;
					text-decoration: none;
				}
			}
		}
	}
}

.footer-contact {
	display: block;
	margin: 35px 0;

	a {
		margin-right: 20px;
		text-transform: uppercase;
		font-size: 20px;
		line-height: 40px;

		&:last-child {
			margin-right: 0;
		}
	}
}

#copyright {
	position: relative;
	color: #fff;
	font-weight: 400;
	letter-spacing: 1px;
	display: block;
	height: 45px;
	line-height: 42px;
	font-family: $montserrat;
	font-size: 12px;
	text-transform: uppercase;
}

#footer {
	position: relative;
}
