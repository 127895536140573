
/* Arrow Button */

.btn {
	display: inline-block;
	text-transform: uppercase;
	padding: 0;

	&.arrow-borders {
		position: relative;
		z-index: 1;
		height: 50px;
		line-height: 50px;
		@include rounded(0);
		font-size: 16px;
		color: $black;
		font-family: $montserrat;
		font-weight: 700;
		padding-left: 15px;
		padding-right: 15px;
		@include transition(all 0.1s ease);

		&.center {
			margin-left: auto;
			margin-right: auto;
		}

		&:before {
			content: '';
			position: absolute;
			top: -1px; left: 0;
			width: 100%;
			height: 51px;
			background: $black;
			z-index: -1;
			-webkit-transform:scaleX(0);
			-ms-transform:scaleX(0);
			transform:scaleX(0);
			-webkit-transition: -webkit-transform .3s;
			transition: transform .3s;
		}

		span {
			display: block;
			position: absolute;
			z-index: 1;
			content: '';
			width: 100px;
			left: 0;
			right: 0;
			margin: 0 auto;

			&.top {
				border-top: 2px solid $black;
				top: -1px;

				&:before {
					display: block;
					position: absolute;
					top: -8px;
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 5px 6px 5px;
					border-color: transparent transparent $black transparent;
					left: 0;
					right: 0;
					margin: auto;
				}
			}

			&.btm {
				border-top: 2px solid $black;
				bottom: -1px;

				&:after {
					display: block;
					position: absolute;
					bottom: -6px;
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 6px 5px 0 5px;
					border-color: $black transparent transparent transparent;
					left: 0;
					right: 0;
					margin: auto;
				}
			}
		}

		&.small span {
			width: 76px;
		}

		&:hover {
			color: $light-teal;
			@include transition(all 0.2s ease);

			&:before {
				-webkit-transform: scaleX(1);
      			-ms-transform: scaleX(1);
          		transform: scaleX(1);
			}
		}
	}
}