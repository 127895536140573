body, html {
	background: $eggshell-blue;
	font-family: $taviraj;
	font-weight: 300;
	color: $black;
	position: relative;

	&.home {
		overflow-x: hidden;
	}
}

.no-pad {
	padding: 0 !important;
}

.row {
	margin: 0;
}

.center {
	text-align: center;
}

a {
	text-decoration: none;
	outline: none;

	&:focus, &:active, &:hover {
		outline: none;
	}
}

figure {
	text-align: left;
}

figcaption {
	display: block;
	text-align: left;
	border-top: 1px solid $light-teal;
	padding: 8px;
	font-family: $taviraj;
	font-size: 12px;
	line-height: 125%;

	span {
		display: inline-block;
		height: 24px;
		position: absolute;
		top: -24px;
		left: 0;
		border: 1px solid $light-teal;
		padding: 0 8px;
		line-height: 20px;
		font-size: 14px;
		font-style: italic;
	}
}

.container {
	position: relative;
}

.container-fluid {
	padding-left: 0;
	padding-right: 0;
}

.peak-row {
	display: block;
	overflow: hidden;
}

.spacer-150 {
	height: 150px;
}

/*******************
 HEADER
*******************/

a.brand {
	display: inline-block;
	position: relative;
}

.banner {
	display: block;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 9995;
	transition: padding 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;

	.orange-logo {
		display: inline-block;
	}

	.white-logo {
		display: none;
		position: absolute;
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $black;
		z-index: 0;
		-webkit-transform: translateY(-100%);
		-moz-transform: translateY(-100%);
		-ms-transform: translateY(-100%);
		-o-transform: translateY(-100%);
		transform: translateY(-100%);
		-webkit-transition: -webkit-transform 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;
		-moz-transition: -moz-transform 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;
		transition: transform 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;

		body.scrollme & {
			-webkit-transform: translateY(0%);
			-moz-transform: translateY(0%);
			-ms-transform: translateY(0%);
			-o-transform: translateY(0%);
			transform: translateY(0%);
		}
	}

	&.autobots {
		padding: 16px 0 16px;
		transition: padding 620ms cubic-bezier(0.57, 0, 0.08, 1) 0s;

		.white-logo {
			display: inline-block;
			margin: auto;
		}

		.orange-logo {
			display: none;
		}
	}
}

.main {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

/* Color Me  */

.bg-light-teal {
	background-color: $light-teal;
}

.bg-white {
	background-color: $white;
}

.bg-eggshell-blue {
	background-color: $eggshell-blue;
}

.bg-gray {
	background-color: $gray;
}

.bg-dark-gray {
	background-color: $black;
}

/* NAV */

nav.nav-primary {
	display: inline;
	-webkit-transition: -webkit-transform 1200ms cubic-bezier(0.57, 0, 0.08, 1) 200ms, opacity 1200ms cubic-bezier(0.245, 0.33, 0.22, 1) 200ms;
	-moz-transition: -moz-transform 1200ms cubic-bezier(0.57, 0, 0.08, 1) 200ms, opacity 1200ms cubic-bezier(0.245, 0.33, 0.22, 1) 200ms;
	transition: transform 1200ms cubic-bezier(0.57, 0, 0.08, 1) 200ms, opacity 1200ms cubic-bezier(0.245, 0.33, 0.22, 1) 200ms;

	&.shrivel {
		-webkit-transition: -webkit-transform 1200ms cubic-bezier(0.57, 0, 0.08, 1) 200ms, opacity 1200ms cubic-bezier(0.245, 0.33, 0.22, 1) 200ms;
		-moz-transition: -moz-transform 1200ms cubic-bezier(0.57, 0, 0.08, 1) 200ms, opacity 1200ms cubic-bezier(0.245, 0.33, 0.22, 1) 200ms;
		transition: transform 1200ms cubic-bezier(0.57, 0, 0.08, 1) 200ms, opacity 1200ms cubic-bezier(0.245, 0.33, 0.22, 1) 200ms;
	}
}

#menu-main-menu {
	display: inline;
	margin: 0;

	li {
		display: inline-block;

		a {
			font-family: $montserrat;
			font-weight: 700;
			text-transform: uppercase;
			font-size: 14px;
			color: $black;
			@include transition(color 0.1s linear);

			body.scrollme & {
				color: $light-teal;
				@include transition(color 0.1s linear);
			}

			&:hover, &:focus, &:active {
				background: none;
				color: $gray-blue;
			}
		}
	}
}


/* MIXINS */

/*********************
TRANSITION
*********************/

@mixin transition($transition...) {
  // defining prefixes so we can use them in mixins below
  $prefixes:      ("-webkit-", "" );
  @each $prefix in $prefixes {
    #{$prefix}transition: $transition;
  }
}

/*********************
BORDER RADIUS
*********************/

/* USAGE: @include rounded(4px);

*/
@mixin rounded($radius: 4px) {
	-webkit-border-radius: $radius;
	-moz-border-radius:    $radius;
	border-radius:         $radius;
}

/* USAGE: @include border-radius(4px,4px,0,0);

*/
@mixin border-radius($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
	-webkit-border-top-right-radius:    $topright;
	-webkit-border-bottom-right-radius: $bottomright;
	-webkit-border-bottom-left-radius:  $bottomleft;
	-webkit-border-top-left-radius:     $topleft;
	-moz-border-radius-topright:        $topright;
	-moz-border-radius-bottomright:     $bottomright;
	-moz-border-radius-bottomleft:      $bottomleft;
	-moz-border-radius-topleft:         $topleft;
	border-top-right-radius:            $topright;
	border-bottom-right-radius:         $bottomright;
	border-bottom-left-radius:          $bottomleft;
	border-top-left-radius:             $topleft;
	-webkit-background-clip:            padding-box;
	-moz-background-clip:               padding;
	background-clip:                    padding-box; 
}

/*********************
Photoshop DROP SHADOW
*********************/

@mixin photoshop-drop-shadow ($angle: 0, $distance: 0, $spread: 0, $size: 0, $color: #000, $inner: false) {
	$angle: (180 - $angle) * pi() / 180; // convert to radians
	$h-shadow: round(cos($angle) * $distance);
	$v-shadow: round(sin($angle) * $distance);
	$css-spread: $size * $spread/100;
	$blur: ($size - $css-spread);
	$inset: if($inner != false, 'inset', '');

	@include box-shadow($h-shadow $v-shadow $blur $css-spread $color unquote($inset));
}


/*********************
Photoshop INNER SHADOW
*********************/

@mixin photoshop-inner-shadow ($angle: 0, $distance: 0, $spread: 0, $size: 0, $color: #000) {
	@include photoshop-drop-shadow ($angle, $distance, $spread, $size, $color, true);
}


/*********************
Photoshop TEXT SHADOW
*********************/

@mixin photoshop-text-shadow ($angle: 0, $distance: 0, $spread: 0, $size: 0, $color: #000) {
	// NOTE: $spread has no effect for text shadows
	$v-shadow: round(sin($angle) * $distance);
	$angle: (180 - $angle) * pi() / 180;
	$h-shadow: round(cos($angle) * $distance);
	$css-spread: $size * $spread/100;
	$blur: ($size - $css-spread);

	@include text-shadow($h-shadow $v-shadow $blur $color);
}

/*********************
OPACITY
*********************/

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}