.pre-loader {
    background-color: $eggshell-blue;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 100000;
}

.spinner {
  width: 80px;
  height: 80px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-animation: sk-rotate 2.0s infinite linear;
  animation: sk-rotate 2.0s infinite linear;

  img {
    display: inline-block;
    width: 64px;
    height: auto;
  }
}