@import "common/variables";
@import "common/mixins";
@import "common/typography";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

/*********************
GLOBAL
*********************/
@import "common/global";
@import "common/buttons";
@import "common/comments";
@import "common/forms";
@import "common/grid";
@import "custom/wp-classes";
@import "custom/tinymce";

/* COMPONENTS */
@import "custom/horizontal-slide";
@import "custom/slide-panel";
@import "custom/preloader";

/* BASE (MOBILE) SIZE */

// styles in base.scss
@import "layouts/base";


/* LARGER MOBILE DEVICES */
@media only screen and (min-width: 600px) {

	// styles in 600up.scss
	@import "layouts/600up";

} // end of media query

/* LARGER MOBILE DEVICES */
@media only screen and (min-width: 768px) {

	// styles in 768up.scss
	@import "layouts/768up";

} // end of media query


/* TABLET & SMALLER LAPTOPS */
@media only screen and (min-width: 900px) {

	// styles in 900up.scss
	@import "layouts/900up";

} // end of media query


/* DESKTOP */
@media only screen and (min-width: 1200px) {

	// styles in 1200up.scss
	@import "layouts/1200up";

} // end of media query


/* LARGE VIEWING SIZE */
@media only screen and (min-width: 1800px) {

	// styles in 1800up.scss
	@import "layouts/1800up";

} // end of media query

